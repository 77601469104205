/* eslint-disable no-unused-vars */
import React, { useState, useEffect, Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider } from './Context/AuthContext';
import { BecarioProvider } from './Context/BecarioContext';
import { AdminProvider } from './Context/AdminContext';
import { Modal } from 'antd';
import './App.css';
import './styles/global.css';

// Componentes cargados de manera perezosa (lazy loading)
const Login = lazy(() => import('./Components/Auth/Login/Login'));
const Home = lazy(() => import('./Components/Home/Home'));
const ForgotPassword = lazy(() => import('./Components/Auth/ForgotPassword/ForgotPassword'));
const ResetPassword = lazy(() => import('./Components/Auth/ResetPassword/ResetPassword'));
const HomeBecario = lazy(() => import('./Components/Home/HomeBecario'));
const ProtectedRoute = lazy(() => import('./Routes/ProtectedRoute'));
import Loader from './Components/General/Loader/Loader';

function App() {
  const [isOnline, setIsOnline] = useState(navigator.onLine); // Estado inicial de la conexión
  const [isModalVisible, setIsModalVisible] = useState(false); // Controla si el modal está visible

  useEffect(() => {
    function handleOnline() {
      setIsOnline(true);
      setIsModalVisible(false); // Cerrar el modal si la conexión se restablece
    }

    function handleOffline() {
      setIsOnline(false);
      setIsModalVisible(true); // Mostrar el modal si está sin conexión
    }

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  const handleOk = () => {
    if (navigator.onLine) {
      setIsOnline(true);
      setIsModalVisible(false);
    } else {
      Modal.warning({
        title: 'Todavía sin conexión',
        content: 'Aún no tienes conexión a Internet. Intenta nuevamente más tarde.',
      });
    }
  };

  return (
    <BrowserRouter>
      <AuthProvider>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/admin" element={<AdminProvider> <ProtectedRoute element={Home} adminOnly={true} /> </AdminProvider>} />
            <Route path="/home" element={<BecarioProvider> <ProtectedRoute element={HomeBecario} /> </BecarioProvider>} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </Suspense>
      </AuthProvider>

      <Modal
        title="Sin conexión"
        open={isModalVisible}
        onOk={handleOk}
        okText="Reintentar"
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <p>No tienes conexión a Internet. Por favor, intenta nuevamente más tarde.</p>
      </Modal>
    </BrowserRouter>
  );
}

export default App;