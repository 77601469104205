import { registerSW } from 'virtual:pwa-register';

const updateSW = registerSW({
  onNeedRefresh() {
    const userConfirmed = confirm('¡Nueva versión disponible! ¿Deseas actualizar ahora?');
    if (userConfirmed) {
      updateSW(); // Esto recarga la página y actualiza la PWA
    }
  },
  onOfflineReady() {
  },
});

export default updateSW