import axios from 'axios';
import { getToken, getRefreshToken, storeToken, storeRefreshToken } from './indexedDB';
import { showNotification, handleSessionCleanup } from '../helpers/Helpers';

// Función para manejar los errores API
const handleApiError = async (error) => {
  if (error.response && error.response.data instanceof Blob) {
    try {
      // Intentar convertir el Blob en un objeto JSON si es un error de tipo blob
      const errorText = await error.response.data.text();
      const errorData = JSON.parse(errorText);
      return { success: false, message: errorData.message || 'Archivo no ecnontrado' };
    } catch (err) {
      return { success: false, message: 'Error inesperado al procesar la solicitud.' };
    }
  } else if (error.response) { 
    return { success: false, message: error.response.data.message || 'Error al procesar la solicitud en el servidor.' };
  } else if (error.request) {
    return { success: false, message: 'No se pudo conectar con el servidor. Por favor, intenta más tarde.' };
  } else {
    return { success: false, message: error.message || 'Ocurrió un error inesperado.' };
  }
};

const privateAxios = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL || 'http://localhost:3000',
});

privateAxios.interceptors.request.use(async (config) => {
  const token = await getToken();
  if (token) 
    config.headers['Authorization'] = `Bearer ${token}`;

  const refreshToken = await getRefreshToken();
  if (refreshToken) 
    config.headers['x-refresh-token'] = refreshToken;

  return config;
}, (error) => {
  return Promise.reject(error);
});

privateAxios.interceptors.response.use(async(response) => {
  // Manejar nuevos tokens en la respuesta
  const newToken = response.headers['x-new-token'];
  const newRefreshToken = response.headers['x-new-refresh-token'];
  if (newToken) 
    await storeToken(newToken);

  if (newRefreshToken) 
    await storeRefreshToken(newRefreshToken);

  // Verificar si la respuesta es un archivo PDF
  const contentType = response.headers['content-type'];
  if (contentType === 'application/pdf')
    return response;

  // Mostrar notificación si la respuesta incluye mensaje
  const { success, message } = response.data;
  if (message) {
    const notificationType = success ? 'success' : 'error';
    showNotification(notificationType, 'Completado', message, 10);
  }

  return response;
}, async (error) => {
  // Verifica si el error es de autenticación
  if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      await handleSessionCleanup();
      const errorMsg = error.response.data.message || (error.response.status === 401 ? 'Sin autorización' : 'Acceso prohibido');

      showNotification('error', 'Error de Autenticación', errorMsg, 10);
      return Promise.reject(error);
  } else {
      // Si no es un error de autenticación, maneja el error general
      const errorHandled = await handleApiError(error);
      showNotification('error', 'Error', errorHandled.message, 10);
      return Promise.reject(error);
  }
});

export default privateAxios;